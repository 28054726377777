import React, { useState } from 'react'
import { Email } from 'components/forms/elements/v2/email'
import { FieldError } from 'components/forms/elements/v2/field-error'
import { Input } from 'components/forms/elements/v2/input'
import { Submit } from 'components/forms/elements/v2/submit'
import ResponseMessage from 'components/forms/elements/response-message'
import Spacer from 'components/new/spacer'
import { Column, Grid } from 'components/grid'
import { useForm } from 'react-hook-form'
import { useIsMounted } from 'hooks/use-is-mounted'

const SUBMIT_STATE = {
  success: 'success',
  loading: 'loading',
  error: 'error',
}

const PromotionsForm = () => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isValid },
  } = useForm({ mode: 'onBlur' })

  const [submitState, setSubmitState] = useState(null)

  const isMounted = useIsMounted()

  const onSubmit = data => {
    setSubmitState(SUBMIT_STATE.loading)

    const body = JSON.stringify(data)

    fetch('/api/promotions-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body,
    })
      .then(res => {
        if (isMounted()) {
          setSubmitState(res.ok ? SUBMIT_STATE.success : SUBMIT_STATE.error)
        }
      })
      .catch(err => {
        if (isMounted()) {
          console.error('Error submitting form: ', err)
          setSubmitState(SUBMIT_STATE.error)
        }
      })
  }

  const onError = errors => {
    console.error(errors)

    if (isValid) {
      setSubmitState(SUBMIT_STATE.error)
    }
  }
  return (
    <React.Fragment>
      {submitState !== SUBMIT_STATE.success && (
        <form
          name='Promotions Newsletter Signup'
          onSubmit={handleSubmit(onSubmit, onError)}
          method='post'
          id='promotions-form'
          data-netlify='true'
        >
          <Grid>
            <Column columns='1/2'>
              <Input
                register={register}
                label='First Name'
                name='First Name'
                id='promotions-form-first-name'
                required
              />
              {errors['First Name'] ? <FieldError type={errors['First Name'].type} /> : null}
            </Column>
            <Column columns='1/2'>
              <Email
                register={register}
                label='Email'
                name='Email'
                id='promotions-form-email'
                required
              />
              {errors['Email'] ? <FieldError type={errors['Email'].type} /> : null}
            </Column>
          </Grid>
          <Spacer size='m' variable />
          <div style={{ left: '-9999px', top: '-9999px' }} className='visually-hidden'>
            <label htmlFor='pardot_extra_field' tabIndex='-1'>
              Comments
            </label>
            <input
              ref={register()}
              type='text'
              id='pardot_extra_field'
              name='pardot_extra_field'
              tabIndex='-1'
            />
          </div>
          <Submit loading={isSubmitting || submitState === SUBMIT_STATE.loading} />
        </form>
      )}
      {submitState === SUBMIT_STATE.success && (
        <ResponseMessage
          message='Thanks for subscribing!'
          messageBody="You've been added to our email list. We'll let you know when new promotions are released."
        />
      )}
      {submitState === SUBMIT_STATE.error && (
        <ResponseMessage
          error={true}
          message='Error occurred.'
          messageBody={
            <React.Fragment>
              There was an issue submitting your form. Please try again. If problems persist, please
              contact <a href='mailto:contactus@hutsoninc.com'>our support team</a>.
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  )
}

export default PromotionsForm
