import React from 'react'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import { graphql, Link } from 'gatsby'
import H2 from 'components/typography/h2'
import Button from 'components/button'
import { Helmet } from 'react-helmet'
import Form from 'components/forms/promotions'
import Layout from 'components/layout'
import Content from 'components/content'

const ExpiredPromotion = ({ data: { heroImage } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Promotion Expired | Hutson Inc</title>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>

      <HeroImage title='Promotion Expired' image={heroImage} />

      <Content>
        <H2 textAlign='center'>This promotion has expired</H2>
        <Message>
          We're constantly adding new promotions to our site. Check out our{' '}
          <Link to='/promotions/'>promotions page</Link> to see our current promotions or sign up
          for email alerts whenever we add new offers.
        </Message>

        <StyledLink to='/promotions/'>
          <Button>View current promotions</Button>
        </StyledLink>

        <H2>Sign up for our promotions newsletter</H2>
        <Form />
      </Content>
    </Layout>
  )
}

const Message = styled.p`
  margin: 0 auto 15px;
  max-width: 600px;
  text-align: center;

  @media (min-width: 800px) {
    margin: 0 auto 30px;
  }
`

const StyledLink = styled(Link)`
  display: block;
  margin: 0 auto 40px;
  max-width: 250px;
  text-decoration: none;
  width: 100%;

  @media (min-width: 800px) {
    margin: 0 auto 80px;
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "promos/promotions-bg.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default ExpiredPromotion
